import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1 className="text-center mb-4 text-gold">Privacy Policy</h1>
          <p className="text-muted text-center">Last Updated: 25/10/2025</p>
          <p>
            Welcome to <a href="http://www.ahamarket.ae" className='  text-decoration-underline text-dark'>www.ahamarket.ae</a> (the “Site”),
            operated by AHA Financing Broker (“we,” “us,” or “our”). We value your privacy and are
            committed to protecting your personal data. This Privacy Policy explains how we collect,
            use, disclose, and safeguard your information when you visit our website.
          </p>

          <h2>1. Information We Collect</h2>
          <ul>
            <li>Personal Identification Information: Name, email address, phone number, etc., when you fill out forms or contact us.</li>
            <li>Usage Data: Details of your visits to our website, including traffic data, location data, logs, and other communication data.</li>
            <li>Cookies and Tracking Technologies: We may use cookies to collect information about your browsing behavior. You can control cookie settings in your browser.</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Provide, operate, and maintain our website.</li>
            <li>Improve and personalize your experience on our site.</li>
            <li>Respond to your questions, comments, and feedback.</li>
            <li>Send you marketing communications, updates, or newsletters, which you can opt-out of at any time.</li>
          </ul>

          <h2>3. Disclosure of Your Information</h2>
          <p>We do not share, sell, or rent your personal information to third parties except in the following situations:</p>
          <ul>
            <li>Service Providers: We may share your information with third-party service providers to perform services on our behalf.</li>
            <li>Legal Requirements: We may disclose your information as required by law or in response to valid requests by public authorities.</li>
          </ul>

          <h2>4. Data Security</h2>
          <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet-based site can be 100% secure, and we cannot guarantee absolute security.</p>

          <h2>5. Your Rights</h2>
          <p>Depending on your location, you may have the right to:</p>
          <ul>
            <li>Access, update, or delete the personal information we hold about you.</li>
            <li>Object to or restrict processing of your data.</li>
            <li>Withdraw consent where applicable.</li>
          </ul>
          <p>To exercise these rights, please contact us at <a href="mailto:info@ahamarket.ae">info@ahamarket.ae</a>.</p>

          <h2>6. Third-Party Links</h2>
          <p>Our website may contain links to third-party websites. We are not responsible for the content, privacy policies, or practices of such websites. We encourage you to review the privacy policies of any third-party sites you visit.</p>

          <h2>7. Changes to This Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of the Site following any changes indicates your acceptance of the updated Privacy Policy.</p>

          <h2>8. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          <address>
            Email: <a href="mailto:info@ahamarket.ae" className='text-decoration-underline text-dark'>info@ahamarket.ae</a><br />
            Address: 0665, Tamani Arts Building, Dubai
          </address>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
